<template>
  <div id="inside" style="margin-bottom: 100px">
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-12 text-body px-2 mb-2">
            <button
              class="justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1 text-primary"
              @click="home()"
            >
              <i class="fa fa-chevron-left fa-fw pull-left text-info"></i>
              Voltar
            </button>
          </div>
        </div>
      </div>
      <div class="">
        <h5 class="m-2 mb-4" style="color: black">Você está negociando</h5>
        <HeaderOptions />
      </div>
    </div>
    <div class="container" :style="largura < 500 ? '' : ''">
      <div class="container">
        <div class="card pt-4 pb-4">
          <div class="col-12">
            <h5 class="mb-0 d-flex flex-row align-items-center">
              {{ offerInfo.product }}
            </h5>
            <p class="mb-1" style="font-size: 8pt">
              Contrato: {{ offerInfo.contract }}
            </p>
          </div>
          <hr class="mr-3 ml-3" />
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">
              Valor original da dívida:
            </p>
            <p class="mb-0" style="font-weight: bold">
              {{ formatFloatToReal(parseFloat(offerInfo.originalValue, 10)) }}
            </p>
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">
              Vencimento original da dívida:
            </p>
            <p class="mb-0" style="font-weight: bold">
              {{ formatData(offerInfo.dueDate, "YYYY-MM-DD", "L") }}
            </p>
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">
              Valor atualizado da dívida:
            </p>
            <p class="mb-0" style="font-weight: bold">
              R$
              {{ formatFloatToReal(parseFloat(offerInfo.paymentValue, 10)) }}
            </p>
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">Dias de atraso:</p>
            <p class="mb-0" style="font-weight: bold">
              {{ offerInfo.daysDelay }}
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="m-2 py-1">
        <hr class="" style="border-top: 1px solid #b7b7b7" />
      </div> -->

      <div class="container mt-2 pl-2" v-if="!loadOffers">
        <h5 class="text-bold mt-4" style="color: black">Opções de pagamento</h5>
        <HeaderOptions />
      </div>

      <div class="container" v-if="cardOffers && !loadOffers">
        <div class="row">
          <div
            class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2"
            v-for="(value, key, index) in cardOffers.paymentOptions"
            :key="`${key}-${index}`"
          >
            <div
              class="card p-0"
              :style="largura < 500 ? '' : 'min-height: 258px !important;'"
            >
              <div class="banner" v-if="value.installmentsNumber === 1">
                <!-- <span class="icon">🔥</span> -->
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 12 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 5px; margin-bottom: 4px;"
                >
                  <path
                    d="M3.16172 6.41295C4.15428 4.93822 5.09363 3.53429 5.20824 1.7642C5.23484 1.35961 5.20005 0.950976 5.21438 0.546385C5.22052 0.360273 5.09568 0.101335 5.35354 0.0123249C5.54387 -0.0524096 5.66461 0.153932 5.78945 0.271263C7.14834 1.55179 8.44173 2.89099 9.54685 4.39809C10.8321 6.14997 11.8471 8.02727 11.9843 10.2303C12.1725 13.2283 10.6745 16.2384 7.38982 16.8838C3.29884 17.6889 -0.444237 14.1932 0.0428338 10.0785C0.255671 8.28621 0.904417 6.69617 1.96042 5.24976C2.07707 5.08994 2.17326 4.83505 2.39837 4.86944C2.63168 4.90383 2.6194 5.17693 2.68284 5.36506C2.7647 5.6058 2.8527 5.84248 2.94889 6.07714C2.98572 6.16615 3.05121 6.24303 3.16172 6.41295ZM5.92861 8.01513C5.94908 9.21879 5.57252 10.0442 5.04656 10.8109C4.9381 10.9686 4.78666 11.3409 4.5554 10.9808C4.1461 10.3415 4.03559 10.8473 3.86777 11.1204C3.55465 11.6261 3.37251 12.1885 3.31521 12.7711C3.19242 13.999 3.58331 15.0328 4.64954 15.7388C5.64005 16.3942 6.68582 16.4286 7.70089 15.8197C8.75894 15.1845 9.12732 14.1427 9.02704 13.0017C8.84694 10.9403 7.49215 9.54651 5.92861 8.01513Z"
                    fill="#2C3DA8"
                  />
                </svg>

                <span class="banner-text">MELHOR OFERTA!</span>
              </div>

              <div class="card-body p-4 border-top-0 py-2">
                <div class="row">
                  <div class="col-lg-12 col-12">
                    <h5
                      class="text-bold mb-0"
                      style="color: black"
                      v-if="value.installmentsNumber === 1"
                    >
                      Pagamento à vista
                    </h5>
                    <h5
                      class="text-bold"
                      style="color: black"
                      v-if="value.installmentsNumber !== 1"
                    >
                      Pagamento parcelado
                    </h5>
                  </div>
                </div>

                <!-- PARA MOBILE -->
                <div class="row mt-3" v-if="largura < 500">
                  <div
                    class="d-flex flex-column col-7"
                    v-if="value.discount > 0"
                  >
                    <!-- <div
                      class="text-info price-line"
                      v-if="
                        value.discount > 0 && value.installmentsNumber === 1
                      "
                    >
                      <p class="mr-2 mb-0">De</p>
                      <span class="line-through">
                        {{
                          formatFloatToReal(
                            parseFloat(offerInfo.paymentValue, 10)
                          )
                        }}
                      </span>
                      <p class="ml-2 mb-0">por</p>
                    </div> -->
                    <div
                      class="d-flex mb-0 price-current"
                      v-if="value.installmentsNumber === 1"
                    >
                      {{
                        formatFloatToReal(
                          parseFloat(value.installmentValue, 10)
                        )
                      }}
                      <p
                        class="mt-2 ml-1 mb-0"
                        style="font-weight: bold; font-size: 8pt"
                      >
                        à vista
                      </p>
                    </div>
                    <div class="mb-0" v-if="value.installmentsNumber !== 1">
                      <p class="mr-2 mb-0">Entrada de</p>
                      <p class="price-current mb-0" style="font-size: 16pt">
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                      <p class="mr-2 mb-0">
                        + {{ value.installmentsNumber - 1 }}x de
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column col-8"
                    v-if="value.discount < 0"
                  >
                    <div class="text-info price-line" v-if="value.discount > 0">
                      <p class="mr-2 mb-0">De</p>
                      <span class="line-through">
                        {{
                          formatFloatToReal(
                            parseFloat(offerInfo.paymentValue, 10)
                          )
                        }}
                      </span>
                      <p class="ml-2 mb-0">por</p>
                    </div>
                    <div
                      class="d-flex mb-0 price-current"
                      v-if="value.installmentsNumber === 1"
                    >
                      {{
                        formatFloatToReal(
                          parseFloat(value.installmentValue, 10)
                        )
                      }}
                      <p class="mt-1 ml-2 mb-0" style="font-weight: bold">
                        à vista
                      </p>
                    </div>
                    <div class="mb-0" v-if="value.installmentsNumber !== 1">
                      <p class="mr-2 mb-0">Entrada de</p>
                      <p class="price-current mb-0" style="font-size: 16pt">
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                      <p class="mr-2 mb-0">
                        + {{ value.installmentsNumber - 1 }}x de
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                    </div>
                  </div>

                  <!-- <div
                    class="col-5 text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber === 1"
                  >
                    <p class="mb-0" style="font-size: 8pt">Desconto de até</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div>
                  <div
                    class="col-5 text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber !== 1"
                  >
                    <p class="mb-0 mt-4 mr-2">Desconto de</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div> -->
                </div>

                <!-- PARA DESKTOP -->
                <div class="row mt-3" v-if="largura > 500">
                  <div class="d-flex flex-column col-12">
                    <div
                      class="d-flex align-items-center mb-0 price-current"
                      v-if="
                        value.discount > 0 && value.installmentsNumber === 1
                      "
                      style="font-size: 18pt"
                    >
                      {{
                        formatFloatToReal(
                          parseFloat(value.installmentValue, 10)
                        )
                      }}
                      <!-- <p
                        class="ml-1 mt-2 mb-0"
                        style="font-weight: bold; font-size: 8pt"
                         
                      >
                        à vista
                      </p> -->
                    </div>

                    <div
                      class="mb-0"
                      v-if="value.installmentsNumber !== 1"
                      style="justify-content: space-between"
                    >
                      <p class="mr-2 mb-0">
                        Entrada de
                      </p>
                      <div style="align-items: center">
                        <p class="price-current mb-0">
                          {{
                            formatFloatToReal(
                              parseFloat(value.installmentValue, 10)
                            )
                          }}
                        </p>
                        <p class="mr-2 pt-0 mb-0">
                          + {{ value.installmentsNumber - 1 }}x de
                          {{
                            formatFloatToReal(
                              parseFloat(value.installmentValue, 10)
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column col-12"
                      v-if="value.discount < 0"
                    >
                      <div
                        class="text-info price-line"
                        v-if="value.discount > 0"
                      >
                        <p class="mr-2 mb-0">De</p>
                        <span class="line-through">
                          {{
                            formatFloatToReal(
                              parseFloat(offerInfo.paymentValue, 10)
                            )
                          }}
                        </span>
                        <p class="ml-2 mb-0">por</p>
                      </div>
                      <div
                        class="d-flex mb-0 price-current"
                        style="font-size: 17pt;"
                        v-if="value.installmentsNumber === 1"
                      >
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-3" v-if="value.installmentsNumber === 1">
                  <div
                    class="d-flex mt-2 col-12 col-lg-12 justify-content-center"
                    v-if="value.installmentsNumber === 1"
                  >
                    <p class="mb-0 mr-1">Vencimento:</p>
                    <b
                      ><p style="font-size: 10pt">
                        {{ formatData(cardOffers.dueDate, "YYYY-MM-DD", "L") }}
                      </p></b
                    >
                  </div>
                </div>
                <div
                  class="row pb-1 mt-3"
                  v-if="value.installmentsNumber !== 1"
                >
                  <div
                    class="d-flex mt-1 col-12 col-lg-12 justify-content-center"
                    v-if="value.installmentsNumber !== 1"
                  >
                    <p class="mb-1 mr-1">Vencimento da entrada:</p>
                    <b
                      ><p style="font-size: 10pt">
                        {{ formatData(cardOffers.dueDate, "YYYY-MM-DD", "L") }}
                      </p></b
                    >
                  </div>
                </div>

                <div class="row pb-2 mt-0">
                  <div class="col-xs-8 col-lg-12 col-12 text-center">
                    <button
                      class="btn btn-primary p-3"
                      style="width: 100%; border-radius: 20px"
                      @click="resumoAcordo(value)"
                      v-if="value.installmentsNumber === 1"
                      :disabled="
                        !isEmptyObject(selectedOption) &&
                        selectedOption !== 'Selecionar'
                      "
                    >
                      PAGAR À VISTA
                    </button>
                    <button
                      class="btn mb-2 btn-primary p-3"
                      style="width: 100%; border-radius: 20px"
                      @click="resumoAcordo(value)"
                      v-else
                      :disabled="
                        !isEmptyObject(selectedOption) &&
                        selectedOption !== 'Selecionar'
                      "
                    >
                      PAGAR PARCELADO EM {{ value.installmentsNumber }}X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadOffers"></div>
    </div>
    <div class="container" v-if="loadOffers">
      <ProgressLoading v-bind:loading="loadOffers" />
    </div>
    <div class="container" v-if="!loadOffers">
      <div
        class="row border-top my-3 mx-auto border-info py-4"
        v-if="largura < 500"
      >
        <div class="col-lg-4 my-3">
          <h5 class="mt-3">Ou escolha outra opção parcelada:</h5>
        </div>
        <div class="col-lg-4 mt-0">
          <div class="form-group">
            <div
              class=""
              v-if="selectOtherOffers && !loadOffers && !negociarPagamentoTotal"
            >
              <!-- SELECT ATUAL -->
              <div class="custom-select-no-arrow" @click="toggleDropdown">
                <div class="selected-option">
                  {{ selectedOptionText || "Selecione" }}
                </div>
                <ul v-if="dropdownOpen" class="options-list" id="listOptions">
                  <li class="options-header">
                    <span class="option-column">Entrada</span>
                    <span class="option-column">Valor Parcela</span>
                    <!-- <span class="option-column">Desconto</span> -->
                  </li>
                  <li
                    v-for="(value, index) in selectOtherOffers.paymentOptions"
                    :key="index"
                    @click="selectOption(value)"
                    class="options-item"
                    :class="{ selected: selectedOption === value }"
                  >
                    <span class="option-column"
                      >R${{ formatFloatToReal(value.installmentValue) }}</span
                    >
                    <span class="option-column"
                      >+ {{ value.installmentsNumber - 1 }}x de R${{
                        formatFloatToReal(value.installmentValue)
                      }}</span
                    >
                    <!-- <span class="option-column" v-if="value.discount > 0"
                      >{{ value.discount.toFixed(2).replace(".", ",") }}%</span
                    >
                    <span
                      class="option-column"
                      v-if="value.discount <= 0"
                    ></span> -->
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="form-group"
              v-if="selectOtherOffers && !loadOffers && negociarPagamentoTotal"
            >
              <select
                id="ParcelasSelectPst"
                class="form-control mt-2"
                @change="getValue($event.target.value)"
              >
                <option
                  v-for="(
                    value, key, index
                  ) in selectOtherOffers.paymentOptions"
                  :key="`${key}-${index}`"
                  :value="JSON.stringify(value)"
                >
                  {{ value.installmentsNumber }}x
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                </option>
                <option v-if="!newOffersLoaded">Carregar mais opções</option>
                <option
                  v-if="
                    negociarPagamentoTotal &&
                    newOffersLoaded &&
                    !othersOptionsLoaded
                  "
                >
                  Outras opções de negociação
                </option>
                <option v-if="selectOtherOffers.paymentOptions.length < 1">
                  Não existem mais opções disponíveis
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="my-3 text-center col-lg-2">
          <button
            class="btn mb-2 btn-primary p-3"
            style="width: 100%; border-radius: 20px"
            :disabled="
              selectOtherOffers.paymentOptions.length < 1 ||
              selectedOption === 'Selecione outra opção' ||
              isEmptyObject(selectedOption)
            "
            @click="resumoAcordo(null)"
          >
            Confirmar
          </button>
          <div class="mt-3">
            <a
              @click="resumoAcordo(cardOffers.paymentOptions[0])"
              style="
                color: #0046c0;
                text-decoration: underline;
                cursor: pointer;
              "
              >Quero a melhor oferta</a
            >
          </div>
        </div>
      </div>
      <div
        class="row border-top border-bottom my-3 mx-auto border-info py-4"
        style="align-items: center"
        v-if="largura > 500"
      >
        <div class="col-lg-4">
          <h5>Ou escolha outra opção parcelada:</h5>
        </div>
        <div class="my-3 text-center col-lg-4">
          <div class="">
            <div
              class=""
              v-if="selectOtherOffers && !loadOffers && !negociarPagamentoTotal"
            >

              <!-- SELECT ATUAL -->
              <div class="custom-select-no-arrow" @click="toggleDropdown">
                <div class="selected-option">
                  {{ selectedOptionText || "Selecione" }}
                </div>
                <ul v-if="dropdownOpen" class="options-list" id="listOptions">
                  <li class="options-header">
                    <span class="option-column">Entrada</span>
                    <span class="option-column">Valor Parcela</span>
                    <!-- <span class="option-column">Desconto</span> -->
                  </li>
                  <li
                    v-for="(value, index) in selectOtherOffers.paymentOptions"
                    :key="index"
                    @click="selectOption(value)"
                    class="options-item"
                    :class="{ selected: selectedOption === value }"
                  >
                    <span class="option-column"
                      >R${{ formatFloatToReal(value.installmentValue) }}</span
                    >
                    <span class="option-column"
                      >+ {{ value.installmentsNumber - 1 }}x de R${{
                        formatFloatToReal(value.installmentValue)
                      }}</span
                    >
                    <!-- <span class="option-column" v-if="value.discount > 0"
                      >{{ value.discount.toFixed(2).replace(".", ",") }}%</span
                    >
                    <span
                      class="option-column"
                      v-if="value.discount <= 0"
                    ></span> -->
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="form-group"
              v-if="selectOtherOffers && !loadOffers && negociarPagamentoTotal"
            >
              <select
                id="ParcelasSelectPst"
                class="form-control mt-2"
                @change="getValue($event.target.value)"
              >
                <option
                  v-for="(
                    value, key, index
                  ) in selectOtherOffers.paymentOptions"
                  :key="`${key}-${index}`"
                  :value="JSON.stringify(value)"
                >
                  {{ value.installmentsNumber }}x
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                </option>
                <option v-if="!newOffersLoaded">Carregar mais opções</option>
                <option
                  v-if="
                    negociarPagamentoTotal &&
                    newOffersLoaded &&
                    !othersOptionsLoaded
                  "
                >
                  Outras opções de negociação
                </option>
                <option v-if="selectOtherOffers.paymentOptions.length < 1">
                  Não existem mais opções disponíveis
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="my-3 text-center col-lg-4">
          <button
            class="btn mb-2 btn-primary p-3"
            style="width: 100%; border-radius: 20px"
            :disabled="
              selectOtherOffers.paymentOptions.length < 1 ||
              selectedOption === 'Selecione outra opção' ||
              isEmptyObject(selectedOption)
            "
            @click="resumoAcordo(null)"
          >
            Confirmar
          </button>
        </div>
        <div class="mt-3 m-auto">
          <a
            @click="resumoAcordo(cardOffers.paymentOptions[0])"
            style="color: #0046c0; text-decoration: underline; cursor: pointer"
            >Quero a melhor oferta</a
          >
        </div>
      </div>
    </div>

    <!-- <div class="container">
      <div class="loading-overlay" v-if="loadOffers">
        <ProgressLoading v-bind:loading="loadOffers" />
      </div>
    </div> -->
    <!--  <div class="container" v-if="!loadOffers && negociarPagamentoTotal && !negociarPagamentoTotalAlreadyCalled && (!compareDates(offerInfo.dueDate))">
      <div class="row my-3 mx-auto">
        <div class="col-lg-12 my-3 px-1">
          <button class="btn btn-link mr-1 mt-2 mb-1 btn-lg" @click="negociarPst()">Negociar valor total</button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
  truncateString,
  formatStringToLowerCase,
  formatFloatToReal,
  isEmptyObject,
} from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import HeaderOptions from "../components/HeaderMenu.vue";
import { EventBus } from "@/utils/EventBus";
import { initializeGlobals } from "@/global";
// import Multiselect from "vue-multiselect";

export default {
  name: "propostas",
  components: {
    ProgressLoading,
    HeaderOptions,
    // Multiselect
  },
  data() {
    return {
      form: {
        offerSelected: null,
      },
      loadOffers: false,
      offers: [],
      offerInfo: [],
      largura: null,
      installment: {},
      loadSetAgreement: false,
      cardOffers: null,
      selectOtherOffers: null,
      negociarPagamentoTotal: false,
      negociarPagamentoTotalAlreadyCalled: false,
      newOffersLoaded: false,
      othersOptionsLoaded: false,
      selectedOption: {},
      paymentOptionsId: [],
      isPossiblePst: false,
      agreementType: null,
      loadOffersPst: false,
      userAdm: {},
      ipUser: null,
      dropdownOpen: false,
      selectedOptionText: "Selecione",
    };
  },
  methods: {
    formatValue,
    formatData,
    truncateString,
    formatStringToLowerCase,
    formatFloatToReal,
    isEmptyObject,

    ...mapActions("meusContratos", [
      "ActionGetOffersFaturaPortoSeguro",
      "ActionGetOffersPortoSeguro",
    ]),

    async negociarPst() {
      localStorage.setItem(
        "currentPageName",
        this.$options.name + "-negociar-valor-total"
      );

      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:link:negociar-valor-total";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      window.dataLayer.push({ ...eventData });

      this.negociarPagamentoTotal = false;
      this.agreementType = "ParcelamentoRecupera";

      if (this.negociarPagamentoTotal) {
        this.negociarPagamentoTotal = false;
      } else {
        this.loadOffers = true;
        this.negociarPagamentoTotal = true;
        var result = [];

        // [24,25,36,40,48] primeira chamada
        // [26,27,28,29,30,31,32] clique no select
        // [33,34,35,37,38,39,40] carregar mais parcelas

        try {
          var res = await this.ActionGetOffersPortoSeguro({
            clientCode: this.offerInfo.clientCode,
            credor: this.offerInfo.credor,
            installmentsOptions: [12, 24, 25, 36, 48],
            agreementType: "ParcelamentoRecupera",
            contract: this.offerInfo.contract,
            discount: 0,
            inputValue: 0,
            tag: localStorage.getItem("tag"),
            daysDelay: this.offerInfo.daysDelay,
            UserID: this.userAdm ? this.userAdm.UserID : null,
            UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
            OS: navigator.platform,
            IP: this.ipUser ? this.ipUser : "",
          });

          result.push(res.data.results);

          const paymentOptionCode = result[0].paymentOptionCode;
          let obj = {};
          obj[paymentOptionCode] = [12, 24, 25, 36, 48];
          this.paymentOptionsId.push(obj);

          result.forEach((item) => {
            const filteredOptions = item.paymentOptions.filter((option) => {
              const installments = [12, 24, 36, 48];
              return installments.includes(option.installmentsNumber);
            });
            if (this.offerInfo.contract === item.contract) {
              this.cardOffers = { ...item, paymentOptions: filteredOptions };
            }
          });

          result.forEach((item) => {
            const filteredOptions = item.paymentOptions.filter((option) => {
              const installments = [25];
              return installments.includes(option.installmentsNumber);
            });
            if (this.offerInfo.contract === item.contract) {
              this.selectOtherOffers = {
                ...item,
                paymentOptions: filteredOptions,
              };
            }
          });

          this.selectedOption = this.selectOtherOffers.paymentOptions[0];
          this.loadOffers = false;
          this.negociarPagamentoTotalAlreadyCalled = true;

          this.emitUpdatePageViewEvent(
            "dashboard/negociar-valor-total/propostas"
          );
        } catch (error) {
          this.flashMessage.show({
            time: 3000,
            status: "error",
            position: "top right",
            title: "Erro",
            message:
              "Não foi possível buscar informações de negociação total, tente novamente mais tarde!",
          });
          this.buildAndSetUserData(
            this.userDataStorage.cpfCnpj,
            this.userDataStorage.id,
            this.protocol + "//" + this.host + "/dashboard",
            "Dashboard"
          );
          this.updateCustomData();
          this.eventCallbackNegociarFailureGTM(
            error,
            "Não foi possível buscar informações de negociação total, tente novamente mais tarde!"
          );
          this.$router.push({
            name: "dashboard",
          });
        }
      }
    },

    emitUpdatePageViewEvent(pageName) {
      EventBus.$emit("updatePageView", pageName);
    },

    async newOptions() {
      this.agreementType = "ParcelamentoRecupera";
      if (this.newOffersLoaded) {
        return;
      }
      this.loadOffers = true;
      const today = new Date();
      today.setDate(today.getDate() + 3);
      var result = [];

      try {
        var res = await this.ActionGetOffersPortoSeguro({
          clientCode: this.offerInfo.clientCode,
          credor: this.offerInfo.credor,
          dueDate: today.toJSON().split("T")[0],
          installmentsOptions: [26, 27, 28, 29, 30, 31, 32],
          agreementType: "ParcelamentoRecupera",
          contract: this.offerInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem("tag"),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
        });

        // if(res.data.code !== 200) {
        //   this.flashMessage.show({
        //     time:3000,
        //     status: 'error',
        //     position:'top right',
        //     title: 'Erro',
        //     message: 'Não foi possível buscar outras opções, tente novamente mais tarde!'
        //   });
        //   this.loadOffers = false;
        //   return;
        // }

        result.push(res.data.results);

        const paymentOptionCode = result.paymentOptionCode;
        let obj = {};
        obj[paymentOptionCode] = [
          26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        ];
        this.paymentOptionsId.push(obj);

        result.forEach((item) => {
          const filteredOptions = item.paymentOptions.filter((option) => {
            const installments = [
              26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
            ];
            return installments.includes(option.installmentsNumber);
          });

          filteredOptions.map((value) => {
            const installment = this.selectOtherOffers.paymentOptions.filter(
              (option) => {
                return option.installmentsNumber === value.installmentsNumber;
              }
            );

            if (installment.length < 1) {
              this.selectOtherOffers.paymentOptions.push(value);
            }
          });

          if (this.offerInfo.contract === item.contract) {
            this.selectOtherOffers = {
              ...item,
              paymentOptions: this.selectOtherOffers.paymentOptions,
            };
          }
        });
        this.selectedOption = this.selectOtherOffers.paymentOptions[0];

        this.newOffersLoaded = true;
        this.loadOffers = false;
      } catch (error) {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "Não foi possível buscar ofertas, tente novamente mais tarde!",
        });
        this.buildAndSetUserData(
          this.userDataStorage.cpfCnpj,
          this.userDataStorage.id,
          this.protocol + "//" + this.host + "/dashboard",
          "Dashboard"
        );
        this.updateCustomData();
        this.eventCallbackNegociarFailureGTM(error, null);
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadOffers = false;
      }
    },

    async loadOthersOptions() {
      this.agreementType = "ParcelamentoRecupera";
      this.othersOptionsLoaded = false;

      this.loadOffers = true;
      const today = new Date();
      today.setDate(today.getDate() + 3);
      var result = [];

      try {
        var res = await this.ActionGetOffersPortoSeguro({
          clientCode: this.offerInfo.clientCode,
          credor: this.offerInfo.credor,
          dueDate: today.toJSON().split("T")[0],
          installmentsOptions: [33, 34, 35, 37, 38, 39, 40],
          agreementType: "ParcelamentoRecupera",
          contract: this.offerInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem("tag"),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
        });

        result.push(res.data.results);

        const paymentOptionCode = result.paymentOptionCode;
        let obj = {};
        obj[paymentOptionCode] = [33, 34, 35, 37, 38, 39, 40];
        this.paymentOptionsId.push(obj);

        result.forEach((item) => {
          const filteredOptions = item.paymentOptions.filter((option) => {
            const installments = [33, 34, 35, 37, 38, 39, 40];
            return installments.includes(option.installmentsNumber);
          });

          filteredOptions.map((value) => {
            const installment = this.selectOtherOffers.paymentOptions.filter(
              (option) => {
                return option.installmentsNumber === value.installmentsNumber;
              }
            );

            if (installment.length < 1) {
              this.selectOtherOffers.paymentOptions.push(value);
            }
          });
          if (this.offerInfo.contract === item.contract) {
            this.selectOtherOffers = {
              ...item,
              paymentOptions: this.selectOtherOffers.paymentOptions,
            };
          }
        });

        this.selectedOption = this.selectOtherOffers.paymentOptions[0];

        this.othersOptionsLoaded = true;
        this.loadOffers = false;
      } catch (error) {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "Não foi possível buscar ofertas, tente novamente mais tarde!",
        });
        this.buildAndSetUserData(
          this.userDataStorage.cpfCnpj,
          this.userDataStorage.id,
          this.protocol + "//" + this.host + "/dashboard",
          "Dashboard"
        );
        this.updateCustomData();
        this.eventCallbackNegociarFailureGTM(error, null);
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadOffers = false;
      }
    },

    async back() {
      this.eventClickArrowToBackGTM();
      this.negociarPagamentoTotal = false;
      await this.loadParcelamentoFatura();
      await this.verifyIfHasPst();
    },

    isDatePassedMoreThan30Days(dateString) {
      const date = new Date(dateString);
      const currentDate = new Date();

      const differenceInTime = currentDate.getTime() - date.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      return differenceInDays > 30;
    },

    async loadParcelamentoFatura() {
      this.loadOffers = true;
      this.agreementType = "ParcelamentoFatura";

      try {
        var result = await this.ActionGetOffersFaturaPortoSeguro({
          clientCode: this.offerInfo.clientCode,
          credor: this.offerInfo.credor,
          agreementType: "ParcelamentoFatura",
          contract: this.offerInfo.contract,
          tag: localStorage.getItem("tag"),
          daysDelay: this.offerInfo.daysDelay,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
          paymentValue: this.offerInfo.paymentValue,
        });

        const filteredOptions = result.paymentOptions.filter((option) => {
          const installments = [1, 3, 6, 12, 24];
          return installments.includes(option.installmentsNumber);
        });

        this.cardOffers = {
          ...result,
          paymentOptions: filteredOptions,
        };


        const filteredOptionsSelected = result.paymentOptions.filter(
          (option) => {
            const installments = [1, 3, 6, 12, 24];
            return !installments.includes(option.installmentsNumber);
          }
        );
        this.selectOtherOffers = {
          ...result,
          paymentOptions: filteredOptionsSelected,
        };

        // this.selectedOption = `Entrada  &nbsp;`;
      } catch (error) {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "Não foi possível buscar ofertas, tente novamente mais tarde!",
        });
        this.eventCallbackNegociarFailureGTM(error, null);
        this.buildAndSetUserData(
          this.userDataStorage.cpfCnpj,
          this.userDataStorage.id,
          this.protocol + "//" + this.host + "/dashboard",
          "Dashboard"
        );
        this.updateCustomData();
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadOffers = false;
      }
    },

    // async loadParcelamentoFatura() {
    //   this.loadOffers = true;
    //   this.agreementType = "ParcelamentoFatura";

    //   try {
    //     var result = await this.ActionGetOffersFaturaPortoSeguro({
    //       clientCode: this.offerInfo.clientCode,
    //       credor: this.offerInfo.credor,
    //       agreementType: "ParcelamentoFatura",
    //       contract: this.offerInfo.contract,
    //       tag: localStorage.getItem("tag"),
    //       daysDelay: this.offerInfo.daysDelay,
    //       UserID: this.userAdm ? this.userAdm.UserID : null,
    //       UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
    //       OS: navigator.platform,
    //       IP: this.ipUser ? this.ipUser : "",
    //     });

    //     const filteredOptions = result.paymentOptions.filter((option) => {
    //       const installments = [1, 3, 6, 12, 24];
    //       return installments.includes(option.installmentsNumber);
    //     });
    //     this.cardOffers = { ...result, paymentOptions: filteredOptions };

    //     const filteredOptionsSelected = result.paymentOptions.filter(
    //       (option) => {
    //         const installments = [1, 3, 6, 12, 24];
    //         return !installments.includes(option.installmentsNumber);
    //       }
    //     );
    //     this.selectOtherOffers = {
    //       ...result,
    //       paymentOptions: filteredOptionsSelected,
    //     };

    //     this.selectedOption = this.selectOtherOffers.paymentOptions[0];
    //   } catch (error) {
    //     this.flashMessage.show({
    //       time: 3000,
    //       status: "error",
    //       position: "top right",
    //       title: "Erro",
    //       message:
    //         "Não foi possível buscar ofertas, tente novamente mais tarde!",
    //     });
    //     this.eventCallbackNegociarFailureGTM(error, null);
    //     this.buildAndSetUserData(
    //       this.userDataStorage.cpfCnpj,
    //       this.userDataStorage.id,
    //       this.protocol + "//" + this.host + "/dashboard",
    //       "Dashboard"
    //     );
    //     this.updateCustomData();
    //     this.$router.push({
    //       name: "dashboard",
    //     });
    //   } finally {
    //     this.loadOffers = false;
    //   }
    // },

    getValue(selectedValue) {
      if (selectedValue === "Selecionar") {
        this.selectedOption = selectedValue;
        return;
      }
      if (selectedValue === "Carregar mais opções") {
        this.newOptions();
      }
      if (selectedValue === "Outras opções de negociação") {
        this.loadOthersOptions();
      } else {
        const value = JSON.parse(selectedValue);
        this.eventSelecionarOutrasOpcoesNegociacaoGTM(value);
        return (this.selectedOption = value);
      }
    },

    async toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDivOptions() {
      var options = document.getElementById("listOptions");
      if (options) {
        options.style.display = "none";
      }
    },
    async selectOption(value) {
      if (this.selectedOption === value) {
        this.selectedOption = {};
        this.selectedOptionText = "Selecionar";
      } else {
        // Caso contrário, marque-a como selecionada
        this.selectedOptionText = `R$${formatFloatToReal(
          value.installmentValue
        )} + ${value.installmentsNumber - 1}x de R$${formatFloatToReal(
          value.installmentValue
        )} ${
          value.discount > 0
            ? value.discount.toFixed(2).replace(".", ",") + "%"
            : ""
        }`;
        this.selectedOption = value;
      }
      // Fecha o dropdown após a seleção
      this.closeDivOptions();
      // this.dropdownOpen = false;
    },

    async resumoAcordo(offer) {
      if (offer === null) {
        offer = this.selectedOption;
      }

      this.eventClickConfirmarGTM(offer);
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/resumoAcordo",
        "resumo-acordo"
      );
      this.updateCustomData();
      this.$router.push({
        name: "resumoAcordo",
        params: {
          offerInfo: {
            clientCode: this.offerInfo.clientCode,
            credor: this.offerInfo.credor,
            product: this.offerInfo.product,
            dueDate: this.cardOffers.dueDate,
            dueDateOriginal: this.offerInfo.dueDate,
            contract: this.offerInfo.contract,
            paymentOptionCode: this.cardOffers.paymentOptionCode,
            paymentOptionsCodeIds: this.paymentOptionsId,
            allowRenegociation: this.offerInfo.allowRenegociation,
            invoiceOption: offer.invoiceOption,
            agreementType: this.agreementType,
            paymentValue: this.offerInfo.paymentValue,
            originalValue: this.offerInfo.originalValue,
            daysDelay: this.offerInfo.daysDelay,
            ...offer,
          },
          fromProposals: true,
          isPst:
            this.negociarPagamentoTotal &&
            this.negociarPagamentoTotalAlreadyCalled,
          from: "propostas",
          // offerInfo: this.offerInfo,
        },
      });
    },

    compareDates(date) {
      let parts = date.split("-");
      let today = new Date();

      date = new Date(parts[0], parts[1] - 1, parts[1]);

      return date >= today ? true : false;
    },

    home() {
      this.eventClickArrowToBackGTM();
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard",
        "Dashboard"
      );
      this.updateCustomData();
      this.$router.push({
        name: "dashboard",
        params: this.offerInfo,
      });
    },

    async verifyIfHasPst() {
      this.loadOffersPst = true;
      this.negociarPagamentoTotalAlreadyCalled = false;

      try {
        var result = [];
        var res = await this.ActionGetOffersPortoSeguro({
          clientCode: this.offerInfo.clientCode,
          credor: this.offerInfo.credor,
          installmentsOptions: [24],
          agreementType: "ParcelamentoRecupera",
          contract: this.offerInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem("tag"),
          daysDelay: this.offerInfo.daysDelay,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
        });

        result.push(res.data.results);
        this.negociarPagamentoTotal = true;
        this.loadOffersPst = false;
      } catch (error) {
        this.negociarPagamentoTotal = false;
        this.loadOffersPst = false;
        return false;
      }
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
        cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
        id: id, // Substitua pelo ID real
        page_location: pageLocation,
        pageName: pageName,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickArrowToBackGTM() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:button:voltar";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventClickConfirmarGTM(offer) {
      var typeConfirm =
        this.negociarPagamentoTotal && this.negociarPagamentoTotalAlreadyCalled
          ? "confirmar"
          : "confirmar";
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = `click:button:${typeConfirm}`;

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: this.buildPayloadOfferChoose(offer),
        product_identify: this.offerInfo.contract,
        product_category: this.offerInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.offerInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    buildPayloadOfferChoose(offer) {
      var formatedPayload = null;
      if (offer.installmentsNumber === 1) {
        formatedPayload = "pagamento-a-vista";
      } else {
        formatedPayload = `parcelado-em-${offer.installmentsNumber}-vezes`;
      }
      return formatedPayload;
    },

    buildPayloadOfferChooseOtherOptionsOfNegociation(offer) {
      var formatedPayload = null;
      formatedPayload = `${offer.installmentsNumber}x ${this.formatFloatToReal(
        parseFloat(offer.installmentValue, 10)
      )}`;

      return truncateString(formatedPayload, 100);
    },

    eventSelecionarOutrasOpcoesNegociacaoGTM(offer) {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "selecionou:option:outra-opcao-de-negociacao";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: this.buildPayloadOfferChooseOtherOptionsOfNegociation(offer),
        product_identify: this.offerInfo.contract,
        product_category: this.offerInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.offerInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventCallbackNegociarFailureGTM(error, msg) {
      console.log("errorrrrr: " + JSON.stringify(error.status));
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "solicitar-opcoes-pagamento:alert";
      const eventLabel = truncateString(
        msg !== null
          ? msg
          : "Não foi possível buscar ofertas, tente novamente mais tarde!",
        100
      );
      const eventCode = error.status;
      const eventErrorService = "";
      const eventAlertService = truncateString(error.data.message, 100);
      const eventAlert = "select_content";
      const eventAlertType = "erro";
      const eventData = {
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: eventLabel,
        alert_code: eventCode,
        error_service: eventErrorService,
        alert_service_message: eventAlertService,
        alert_event: eventAlert,
        alert_type: eventAlertType,
      };

      window.dataLayer.push({ event: "alert", ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    },
  },

  async created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem("ipUser");
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + "//" + this.host + this.path;
    this.negociarPagamentoTotal = false;
    this.negociarPagamentoTotalAlreadyCalled = false;
    this.offerInfo = this.$route.params.offerInfo;
    await this.loadParcelamentoFatura();
    this.largura = window.screen.width;
    await this.verifyIfHasPst();
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>

<style scoped>
</style>
